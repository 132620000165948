export default class ParallaxEvent {
  constructor() {
    this.init();
  }

  init() {
    const slitInners = document.querySelectorAll('.js-slitInner');
    const Parallaxs = document.querySelectorAll('.js-Parallax');

    let body = bodyOrHtml();

    document.addEventListener('scroll', function () {
      for (let i = 0; i < slitInners.length; i++) {
        let TRHTopBgPlxBound = slitInners[i].getBoundingClientRect();
        let TRHTopBgBoundPx = TRHTopBgPlxBound.top + window.pageYOffset;
        if (slitInners[i].classList.contains('Career')) {
          if (window.innerWidth > 768) {
            calcPos(slitInners[i], TRHTopBgBoundPx, '52%');
          } else {
            calcPos(slitInners[i], TRHTopBgBoundPx, '46%');
          }
        } else {
          calcPos(slitInners[i], TRHTopBgBoundPx, '1.75%');
        }
      }
      for (let j = 0; j < Parallaxs.length; j++) {
        let TRHTopBgPlxBound = Parallaxs[j].getBoundingClientRect();
        let TRHTopBgBoundPx = TRHTopBgPlxBound.top + window.pageYOffset;
        calcMoves(Parallaxs[j], TRHTopBgBoundPx);
      }
    });

    for (let i = 0; i < slitInners.length; i++) {
      let TRHTopBgPlxBound = slitInners[i].getBoundingClientRect();
      let TRHTopBgBoundPx = TRHTopBgPlxBound.top + window.pageYOffset;
      if (slitInners[i].classList.contains('Career')) {
        if (window.innerWidth > 768) {
          calcPos(slitInners[i], TRHTopBgBoundPx, '52%');
        } else {
          calcPos(slitInners[i], TRHTopBgBoundPx, '46%');
        }
      } else {
        calcPos(slitInners[i], TRHTopBgBoundPx, '1.75%');
      }
    }

    function calcPos(slitInner, TRHTopBgBoundPx, leftPos) {
      let scroll = body.scrollTop;
      if (
        scroll > TRHTopBgBoundPx - window.innerHeight &&
        scroll < Number(TRHTopBgBoundPx + slitInner.clientHeight + window.innerHeight)
      ) {
        let roundPos = Math.trunc(Number(scroll - TRHTopBgBoundPx) * 0.2);
        // slitInner.style.transform = 'scale(1) translate3d(0, ' + roundPos + 'px, 0)';
        slitInner.style.backgroundPosition = leftPos + ' ' + roundPos + 'px';
      }
    }

    function calcMoves(slitInner, TRHTopBgBoundPx) {
      let scroll = body.scrollTop;
      if (
        scroll > TRHTopBgBoundPx - window.innerHeight &&
        scroll < Number(TRHTopBgBoundPx + slitInner.clientHeight + window.innerHeight)
      ) {
        let roundPos = Math.trunc(Number(scroll - TRHTopBgBoundPx) * 0.1);
        slitInner.style.transform = 'scale(1) translate3d(0, ' + roundPos + 'px, 0)';
        // slitInner.style.backgroundPosition = 'left ' + -roundPos + 'px';
      }
    }

    function bodyOrHtml() {
      if ('scrollingElement' in document) {
        return document.scrollingElement;
      }
      if (navigator.userAgent.indexOf('WebKit') != -1) {
        return document.body;
      }
      return document.documentElement;
    }
  }
}
