import inView from 'in-view';
// import Chaffle from 'chaffle';

export default class InViewEvent {
  constructor() {
    this.init();
  }
  init() {
    // inView.threshold(0.5);
    inView('.js-maskView')
      .on('enter', function (el) {
        el.classList.add('js-maskViewAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });
    inView('.js-maskViewLine')
      .on('enter', function (el) {
        el.classList.add('js-maskViewLineAct');
      })
      .on('exit', (el) => {
        el.classList.remove('js-maskViewLineAct');
      });
    inView('.js-inView')
      .on('enter', function (el) {
        el.classList.add('js-inViewAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });
    inView('.js-inCrossView')
      .on('enter', function (el) {
        el.classList.add('js-inCrossViewAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });

    inView('.js-maskFrameView')
      .on('enter', function (el) {
        el.classList.add('js-maskFrameViewAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });
  }
}
