export default class FormEvent {
  constructor() {
    this.init();
  }

  init() {
    const selects = document.querySelectorAll('.js-select');
    for (let i = 0; i < selects.length; i++) {
      if (selects[i].value) {
        selects[i].parentElement.classList.remove('Null');
        selects[i].classList.remove('Null');
      } else {
        selects[i].parentElement.classList.add('Null');
        selects[i].classList.add('Null');
      }
      selects[i].addEventListener('change', function (e) {
        // alert(Boolean(e.currentTarget.value));
        let target = e.currentTarget;
        let value = target.value;
        if (value) {
          target.parentElement.classList.remove('Null');
          target.classList.remove('Null');
        } else {
          target.parentElement.classList.add('Null');
          target.classList.add('Null');
        }
      });
    }
  }
}
