// import Swiper JS
// import Swiper, { Navigation, Pagination } from 'swiper';
export default class KVSlider {
  constructor() {
    this.motionID;
    this.KVBox;
    this.KVBoxs;
    this.motionValue = [0, 0, 0];
    this.motionValue1 = 0;
    this.motionValue2 = 0;
    this.motionValue3 = 0;
    this.motionAdd = 0.2;
    this.mediaQuery = window.matchMedia('(max-width: 768px)');
    this.limit = -55;
    this.direction = 'X';
    this.init();
  }

  init() {
    this.KVBox = document.querySelector('.js-PageKVBox');
    if (!this.KVBox) {
      return false;
    }
    this.KVBoxs = this.KVBox.querySelectorAll('.js-PageKVBoxItem');
    // window.addEventListener('scroll', () => {
    //   this.motionAdd = window.scrollY / 500;
    //   if (this.motionAdd < 0.2) {
    //     this.motionAdd = 0.2;
    //   }
    //   if (this.motionAdd > 0.7) {
    //     this.motionAdd = 0.7;
    //   }
    // });
    this.mediaQuery.addListener((e) => {
      this.handle(e.matches);
    });
    this.handle(this.mediaQuery.matches);
    this.tick(this);
    this.scrollEvent(this);
  }

  scrollEvent(t) {
    let prevScroll = window.pageYOffset;
    let BoolScroll = true;
    let DownSpeed = false;
    let int;
    window.addEventListener('scroll', () => {
      if (DownSpeed) {
        DownSpeed = false;
        clearInterval(int);
        BoolScroll = false;
        easingFuncUp(t, 3);
        return false;
      }
      if (!BoolScroll) {
        return false;
      }
      // if (DownSpeed) {
      //   DownSpeed = false;
      //   clearInterval(int);
      //   BoolScroll = false;
      //   easingFuncUp(t, 3);
      //   return false;
      // }
      // console.log(prevScroll - window.scrollY);
      // Diff = Math.abs(prevScroll - window.scrollY);
      // if (Diff > 1) {
      //   Diff = 1;
      // }
      // t.motionAdd = Diff;
      BoolScroll = false;
      easingFuncUp(t, 1);
      // if (BoolDiff) {
      //   setTimeout(() => {
      //     prevScroll = window.scrollY;
      //     BoolDiff = true;
      //     BoolScroll = false;
      //     console.log('Reset');
      //     easingFunc(t, 0.2);
      //   }, 1000);
      // }
      // BoolDiff = false;
    });

    function easingFunc(t, target) {
      const fps = 1000 / 30;
      const dx = target;
      const speed = 0.1;
      let _tmp = t.motionAdd;
      function cal() {
        _tmp += (dx - _tmp) * speed;
        t.motionAdd = _tmp;
        if (_tmp < 0.205) {
          clearInterval(int);
          BoolScroll = true;
          t.motionAdd = 0.2;
        }
      }
      int = setInterval(cal, fps);
    }

    function easingFuncUp(t, target) {
      const fps = 1000 / 30;
      const dx = target;
      const speed = 0.2;
      let _tmp = t.motionAdd;
      function cal() {
        _tmp += (dx - _tmp) * speed;
        t.motionAdd = _tmp;
        if (_tmp > 0.999) {
          DownSpeed = true;
          t.motionAdd = 1;
          // BoolScroll = true;
          clearInterval(int);
          easingFunc(t, 0.2);
        }
      }
      int = setInterval(cal, fps);
    }
  }

  handle(mm) {
    if (mm) {
      this.limit = -65;
      this.direction = 'Y';
    } else {
      this.limit = -55;
      this.direction = 'X';
    }
  }

  tick(t) {
    t.render();
    t.motionID = requestAnimationFrame(function () {
      t.tick(t);
      // t.render();
    });
  }

  randomMovie() {
    const frames = document.querySelectorAll('.js-PageKVBoxItem');
    const Name = document.querySelector('.topPageKVCredit');
    const Link = document.querySelector('.topPageKVCreditLink').querySelector('a');
    const myArray = [
      [
        'https://player.vimeo.com/video/716685723?h=da1ba993fc',
        'Takuma Miyamoto',
        'https://opensea.io/assets/matic/0x4d77c693c2ccd391ad2f2d7898dc7cc9b974b284/3',
      ],
      [
        'https://player.vimeo.com/video/716686092?h=3e9677a9b3',
        'Takuma Miyamoto',
        'https://opensea.io/assets/matic/0x4d77c693c2ccd391ad2f2d7898dc7cc9b974b284/2',
      ],
      [
        'https://player.vimeo.com/video/715429399?h=814ad2d22c',
        'Kaoru Tanaka',
        'https://opensea.io/assets/matic/0x4d77c693c2ccd391ad2f2d7898dc7cc9b974b284/0',
      ],
      [
        'https://player.vimeo.com/video/715429550?h=a0cab6e216',
        'Kaoru Tanaka',
        'https://opensea.io/assets/matic/0x4d77c693c2ccd391ad2f2d7898dc7cc9b974b284/1',
      ],
    ];

    const rand = Math.floor(Math.random() * myArray.length);
    const rValue = myArray[rand][0];
    for (let i = 0; i < frames.length; i++) {
      frames[i].innerHTML =
        '<iframe class="js-PageKVBoxItemFrame" src="' +
        rValue +
        '&title=0&byline=0&portrait=0&speed=0&badge=0&autoplay=0&loop=1&autopause=0&background=1&player_id=0&app_id=58479/embed" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen frameborder="0"></iframe>';
    }
    Name.innerText = 'Visual : ' + myArray[rand][1];
    Link.setAttribute('href', myArray[rand][2]);
  }
  render() {
    this.motionValue1 = this.motionValue1 - this.motionAdd;
    if (this.motionValue1 <= -99.9999) {
      this.motionValue1 = 200;
    }

    this.motionValue2 = this.motionValue2 - this.motionAdd;
    if (this.motionValue2 <= -199.9999) {
      this.motionValue2 = 100;
    }

    this.motionValue3 = this.motionValue3 - this.motionAdd;
    if (this.motionValue3 <= -299.9999) {
      this.motionValue3 = 0;
    }
    this.KVBoxs[0].style.transform = 'translate' + this.direction + '(' + this.motionValue1 + '%)';
    this.KVBoxs[1].style.transform = 'translate' + this.direction + '(' + this.motionValue2 + '%)';
    this.KVBoxs[2].style.transform = 'translate' + this.direction + '(' + this.motionValue3 + '%)';

    // for (let i = 0; i < this.KVBoxs.length; i++) {
    //   if (i == 0) {
    //     this.motionValue1 = this.motionValue1 - this.motionAdd;
    //     if (this.motionValue1 < -99.9999) {
    //       this.motionValue1 = 200;
    //     }
    //     this.KVBoxs[0].style.transform =
    //       'translate' + this.direction + '(' + this.motionValue1 + '%)';
    //   }
    //   if (i == 1) {
    //     this.motionValue2 = this.motionValue2 - this.motionAdd;
    //     if (this.motionValue2 < -199.9999) {
    //       this.motionValue2 = 100;
    //     }
    //     this.KVBoxs[1].style.transform =
    //       'translate' + this.direction + '(' + this.motionValue2 + '%)';
    //   }
    //   if (i == 2) {
    //     this.motionValue3 = this.motionValue3 - this.motionAdd;
    //     if (this.motionValue3 < -299.9999) {
    //       this.motionValue3 = 0;
    //     }
    //     this.KVBoxs[2].style.transform =
    //       'translate' + this.direction + '(' + this.motionValue3 + '%)';
    //   }
    // }
  }

  decimalPart(num, decDigits) {
    var decPart = num - (num >= 0 ? Math.floor(num) : Math.ceil(num));
    return decPart.toFixed(decDigits);
  }
}
