export default class NavEvent {
  constructor() {
    this.init();
  }

  init() {
    const toggle = document.getElementById('js-menuToggle');
    const menu = document.getElementById('js-menuBody');
    const headBar = document.getElementById('js-headerBar');
    const hederLogo = document.querySelector('.p_headerHead');
    const headerWrap = document.querySelector('.p_headerWrap');
    const globalNavWrap = document.querySelector('.c_global_navWrap');
    toggle.addEventListener('click', () => {
      toggle.classList.toggle('Act');
      headBar.classList.toggle('Act');
      menu.classList.toggle('Act');
      hederLogo.classList.toggle('Fix');
      hederLogo.classList.toggle('Act');
      globalNavWrap.classList.toggle('Fix');
      document.body.classList.toggle('fixed');
    });

    const themeToggles = document.querySelectorAll('.js-themeToggle');
    for (let i = 0; i < themeToggles.length; i++) {
      themeToggles[i].addEventListener('click', () => {
        document.body.classList.toggle('Light');
        if (document.body.classList.contains('Light')) {
          sessionStorage.setItem('theme', 'Light');
        } else {
          sessionStorage.setItem('theme', 'Dark');
        }
        for (let j = 0; j < themeToggles.length; j++) {
          themeToggles[j].classList.toggle('Act');
        }
      });
    }
  }
}
