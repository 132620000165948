// import Chaffle from 'chaffle';

// export default class ChaffleEvent {
//   constructor() {
//     this.init();
//   }

//   init() {
//     const elements = document.querySelectorAll('[data-chaffle]');
//     Array.prototype.forEach.call(elements, function (el) {
//       const chaffle = new Chaffle(el, {
//         lang: 'en',
//         speed: 10,
//         delay: 50,
//       });
//       el.addEventListener('mouseover', function (e) {
//         if (!e.currentTarget.dataset.chafflehover) {
//           return false;
//         }
//         e.currentTarget.classList.add('Hover');
//         chaffle.init();
//       });
//       // el.addEventListener('mouseout', function (e) {
//       //   e.currentTarget.classList.remove('Hover');
//       // });
//     });
//   }

//   doneEvent() {
//     if (window.innerWidth < 786) {
//       return false;
//     }
//     const elements = document.querySelectorAll('[data-chaffle]');
//     Array.prototype.forEach.call(elements, function (el) {
//       const chaffle = new Chaffle(el, {
//         lang: 'en',
//         speed: 20,
//         delay: 100,
//       });
//       chaffle.init();
//     });
//   }
// }

import Chaffle from 'chaffle';

export default class ChaffleEvent {
  constructor() {
    this.init();
  }

  init() {}

  doneEvent() {
    const elements = document.querySelectorAll('[data-chaffle]');
    Array.prototype.forEach.call(elements, function (el) {
      const chaffle = new Chaffle(el, {
        lang: 'en',
        speed: 20,
        delay: 100,
      });
      // chaffle.init();
      if (window.innerWidth > 786) {
        //  return false;
        chaffle.init();
      }
      el.addEventListener('mouseover', function (e) {
        if (!e.currentTarget.dataset.chafflehover) {
          return false;
        }
        e.currentTarget.classList.add('Hover');
        chaffle.init();
      });
    });
  }
}
