'use strict';
import inView from 'in-view';
import './structure/adjustVh';
import './structure/adjustViewport';
import InViewEvent from './components/inViewEvent';
import ChaffleEvent from './components/chaffleEvent';
import NavEvent from './components/navEvent';
import KVSlider from './components/kvSlider';
import FormEvent from './components/formEvent';
import ParallaxEvent from './components/ParallaxEvent';

document.addEventListener('DOMContentLoaded', () => {
  const theme = sessionStorage.getItem('theme');
  if (theme == 'Light') {
    document.body.classList.toggle('Light');
    const themeToggles = document.querySelectorAll('.js-themeToggle');
    for (let j = 0; j < themeToggles.length; j++) {
      themeToggles[j].classList.toggle('Act');
    }
  }

  const splash = sessionStorage.getItem('splash');

  const splashAnimation = lottie.loadAnimation({
    container: document.getElementById('loaderLogo'), // Required
    path: '/assets/images/kulture_splash.json', // Required
    renderer: 'svg', // Required
    loop: true, // Optional
    autoplay: true, // Optional
  });

  const headLogoAnimation = lottie.loadAnimation({
    container: document.getElementById('headerLogo'), // Required
    path: '/assets/images/kulture_logo.json', // Required
    renderer: 'svg', // Required
    loop: false, // Optional
    autoplay: false, // Optional
  });

  if (document.getElementById('shapeLogo')) {
    const shapeAnimation = lottie.loadAnimation({
      container: document.getElementById('shapeLogo'), // Required
      path: '/assets/images/kulture_bg_k.json', // Required
      renderer: 'svg', // Required
      loop: false, // Optional
      autoplay: false, // Optional
    });
    inView('#shapeLogo')
      .on('enter', function (el) {
        shapeAnimation.play();
      })
      .on('exit', (el) => {
        shapeAnimation.stop();
        // shapeAnimation.gotoAndStop(0, true);
      });
  }

  const inViewEvent = new InViewEvent();
  const chaffleEvent = new ChaffleEvent();
  const navEvent = new NavEvent();
  const kvSlider = new KVSlider();
  const formEvent = new FormEvent();
  const parallaxEvent = new ParallaxEvent();
  const loader = document.querySelector('.l_loader');
  let splashEnd = false;
  let ReTry = false;
  loader.classList.add('On');
  setTimeout(() => {
    splashEnd = true;
    if (ReTry) {
      done();
    }
  }, 2000);

  let players = [];
  if (document.querySelector('.topPage') || document.querySelector('.aboutPage')) {
    if (document.querySelector('.topPage')) {
      kvSlider.randomMovie();
    }

    // const urlHash = location.hash;
    // if (urlHash) {
    if (splash) {
      loader.classList.add('Remove');
      setTimeout(() => {
        // headLogoAnimation.goToAndStop(60, true);
        headLogoAnimation.play();
        setTimeout(() => {
          navMotion();
        }, 100);
      }, 50);
    } else {
      done();
    }
    // }

    const iframes = document.querySelectorAll('.js-PageKVBoxItemFrame');
    const iframesLeng = iframes.length;
    let loadCount = 0;
    for (let i = 0; i < iframesLeng; i++) {
      let player = new Vimeo.Player(iframes[i]);
      players.push(player);
      player.setVolume(0);
      player.pause();
      player.on('loaded', function (data) {
        if (!splash) {
          loadVimeo(1500);
        } else {
          loadVimeo(0);
        }
      });
    }

    function loadVimeo(sec) {
      loadCount++;
      if (loadCount >= iframesLeng - 1) {
        for (let i = 0; i < players.length; i++) {
          setTimeout(() => {
            players[i].play();
          }, sec);
        }
        if (!splash) {
          done();
        }
      }
    }
  } else {
    if (splash) {
      loader.classList.add('Remove');
      setTimeout(() => {
        // headLogoAnimation.goToAndStop(60, true);
        headLogoAnimation.play();
        setTimeout(() => {
          navMotion();
        }, 100);
      }, 50);
    } else {
      done();
    }
  }

  function done() {
    if (!splashEnd) {
      ReTry = true;
      return false;
    }
    setTimeout(() => {
      loader.classList.add('Hide');
    }, 1600);
    setTimeout(() => {
      // if (document.querySelector('.topPage')) {
      //   headLogoAnimation.play();
      // } else {
      //   headLogoAnimation.goToAndStop(60, true);
      // }
      headLogoAnimation.play();
    }, 1550);
    setTimeout(() => {
      navMotion();
    }, 1600);
    sessionStorage.setItem('splash', 'Done');
  }

  function navMotion() {
    const navSplash = document.querySelectorAll('.js-navSplash');
    for (let i = 0; i < navSplash.length; i++) {
      navSplash[i].classList.add('Act');
    }
    const navSplashV = document.querySelectorAll('.js-navSplashV');
    for (let i = 0; i < navSplashV.length; i++) {
      navSplashV[i].classList.add('Act');
    }
    chaffleEvent.doneEvent();
  }
});
